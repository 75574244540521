
article {

.fnRef {
	position: relative;
	top: -0.5em;
	font-size: .75 * $core-font-size
}

.tablenotes {
	margin-top: $spacing;
	font-size: smaller
}

.tablenote > .marker {
	display: block;
    position: absolute;
}
.tablenote > p {
	margin-left: $indent
}

}

article + .footnotes {
	margin-top: $spacing * 2;
	margin-left: 1in; margin-right: 1in;
	border-top: thin solid black;
	font-size: smaller
}

article + .footnotes {

 .footnote > .marker {
	display: block;
    position: absolute;
	&:after {
		content: '.'
	}
}
.footnote > p {
	margin-left: $indent
}

}
