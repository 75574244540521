
.citations {

padding-top: 3pt;

h1 { text-align: center }

p { text-align: center }

h1 + p { font-size: larger }

p.right { margin: -1em 3em 0; text-align: right }

/* #orderby {
    position: absolute;
    right: 0;
    margin-right: 1em
} */

table {
    border-collapse: collapse;
    min-width: 50%;
    margin: 0 auto;
}

caption { padding: 1em; font-weight: bold; font-size: larger }

thead, tbody.odd { background-color: whitesmoke }

th, td { padding-left: 6pt; padding-right: 6pt }
tr:first-child > * { padding-top: 3pt }
tr:last-child > * { padding-bottom: 3pt }
th { text-align: left }
td { vertical-align: top }

a {
    text-decoration: none;
    color: inherit
}
a:hover { text-decoration: underline }
svg { vertical-align: text-bottom }

.error {
    margin: 1em 1in;
    background-color: LavenderBlush
}

}
